<template>
  <div class="google">
      <Top-banner :img='img' :title='title' :text='text'  :name='name'></Top-banner> 
      <!-- Google的优势 -->
      <advance :title='advTitle' :advList='advList' :advImg='advImg'></advance>
      <!-- 蓝色条 -->
      <div class='google-blueBlock'>
        <div class='bluetitle'>在这里遇见您的全球客户</div>
        <div class='bluetext'>通过 Google Ads，您可以使用各式各样的广告产品来帮助您在关键时刻覆盖客户，您可以全心专注于发展您的业务。</div>
      </div>
      <!-- tab切换 -->
      <my-tab :title='tabTitle' :tabList='tabList'></my-tab>

      <introduction :img='introductionImg' :title='introductionTitle' :title2='introductionTitle2' :title3='introductionTitle3' :btn='introductionBtn' :text='introductionText'></introduction>
    <!-- 您的广告可以展示在 -->
      <div class='container1080'>
        <div class='titleThis'>您的广告可以展示在 </div>
          <div class='googleAdvLeft'>
            <ul class='googleLi'>
              <li v-for='(item,index) in liList' :key='index' @click='changeLi(index)' :class='{checked:index==activeIndex}'>
                <p style='font-size: 16px;margin:10px 0'>{{item.title}}</p>
                <p style='font-size:14px'>{{item.text}}</p>
              </li>
            </ul>
          </div>
          <img :src="lastImg" alt="" class='lastImg'>
      </div>
      <!-- 在海外推广时是否遇到困境？ -->
     <four-card :title='cardTitle' :blockList1='blockList1' :bgColor='"#5270d2"' :titleColor='"#fff"'></four-card>
  <!-- 选择晶运东来让您出海无忧 -->
     <four-card :title='cardTitle2' :text='cardText' :blockList1='blockList1' :bgColor='"#fff"' :titleColor='"rgb(51, 51, 51)"' :border='"1px solid #e7ecee"'></four-card>
    
    <my-model ref='myModel'></my-model>

  </div>
</template>

<script>
import topBanner from '../components/topBanner';
import myTab from '../components/MyTab';
import introduction from '../components/Introduction';
import advance from '../components/Advance';
import fourCard from '../components/FourCard';
import myModel from '../components/MyModel';


export default {
 name: 'Google',
 components:{
   TopBanner:topBanner,
   MyTab:myTab,
   Introduction:introduction,
   Advance:advance,
   FourCard:fourCard,
   MyModel:myModel

 },
  data() {
    return {
     img:require('../static/images/google-bg.jpg'),
     title:'全球第一大搜索引擎',
     text:'拥有众多网盟，只需一个账户您的广告就可以投放到全球，谷歌多样化广告业务解决你的出海问题',
     icon:require('../static/images/googleIcon.png'),
     advTitle:'Google的优势',
     advList:[
       {title:"132种",text:"拥有132种国际语言",img:require('../static/images/google-adv1.png')},
       {title:"90%",text:"拥有全球90%的互联网用户",img:require('../static/images/google-adv2.png')},
       {title:"200多个",text:"覆盖200多个国家和地区",img:require('../static/images/google-adv3.png')},
       {title:"NO.1",text:"全球第一大搜索引擎",img:require('../static/images/google-adv4.png')},
       ],
    advImg:require('../static/images/google-adv.png'),
     name:'Google',
     publicTitle:'跨多个社交平台',
     publicText:"Facebook是全球最大的互动社区，月活跃用户达到20亿以上。其系列应用和服务跨Facebook、Instagram、Audience Network和Messenger等多个平台可提供多种广告类型。",
     btn:'了解更多',
     publicImg:require('../static/images/facebookBlock1.png'),
     
    tabTitle:"多样化广告业务",
    tabList:[
      {tab:"搜索",title:'在客户搜索与您的关键字相关的字词时，您的广告就展示在相应的搜索结果中。',text:'广告展示在搜索结果上方或下方，客户自发通过关键词搜索。搜索广告只展示给有兴趣的人，精准定位目标客户群。',img:require('../static/images/google-tab1.jpg')},
      {tab:"展示 ",title:' 无论用户是在浏览自己喜欢的网站，还是使用移动设备和应用，您都可以向他们展示广告。',text:'帮助您找到合适的受众群体。要通过富有吸引力的广告格式吸引用户，以精彩的图片打动客户',img:require('../static/images/google-tab2.jpg')},
      {tab:"购物",title:'您所销售产品的详细信息作为主要内容将展示在页面上方。',text:'向用户展示产品照片，名称和价格，吸引更优质的潜在客户。您在一次搜索中吸引购物者注意的几率可能会成倍吸引购物者。',img:require('../static/images/google-tab3.jpg')},
      {tab:"视频",title:' 您的广告将在YouTube搜索结果中、相关视频旁以及移动 YouTube首页上展示。 ',text:'视频发现广告只能在 YouTube 上展示。优质低价的视频广告可以生动全面的展示企业实力和提高品牌认知度。',img:require('../static/images/google-tab4.jpg')},
      {tab:"应用",title:' 您只要提供候选广告文字和素材资源，它将设计一系列采用多 种格式和面向各种广告网络的广告。',text:'应用广告为您网罗对同类应用最感兴趣的受众群体。制定最想要的广告目标，谷歌自会将您的广告展示给最合适的用户。  ',img:require('../static/images/google-tab5.jpg')},
    ],
    introductionTitle:'Google',
    introductionTitle2:'google',
    introductionTitle3:'广告介绍',
    introductionText:'Google Ads是Google拥有和运营的在线广告平台。Ads也是全球规模最大，使用最广泛的在线广告网络，数百万家企业使用AdWords在线投放广告，以吸引新客户并发展业务。每一秒都有用户访问 Google 搜索处理各种事务所需的信息。Google Ads 是一个可以帮助您覆盖这些受众群体并将他们转化为高价值客户的数字广告解决方案。',
    introductionBtn:'投放广告',
    introductionImg:require('../static/images/google-introduction.jpg'),
    liList:[
      {title:'Google网页',text:"您的广告将展现在Google网页搜索的上方或下方"},
      {title:'Gmail',text:"您的广告将精准投放在用户收件箱的显著位置上"},
      {title:'Youtube',text:"您的广告将以图片横幅、视频内叠式图片、TrueView插播视频等方式展示在Youtube的信息流中。"},
      ],
      activeIndex:0,
      lastImg:require('../static/images/google-last0.png'),
      cardTitle:"在海外推广时是否遇到困境？",
        blockList1:[
       {title:'效果不明显',text:'大笔投入资金迟迟看不到效果',img:require('../static/images/google-block1.png')},
       {title:'多渠道投放',text:'需要选择多渠道投放来覆盖更多的人群',img:require('../static/images/google-block2.png')},
       {title:'缺乏外文专家',text:'缺乏外文营销专家，难以有效跟踪和调整推广',img:require('../static/images/google-block3.png')},
       {title:'受众不精准',text:'海外推广受众不精准，难以看到明显客户转化',img:require('../static/images/google-block4.png')}, 
    ],
    cardTitle2:'选择晶运东来让您出海无忧 ',
    cardText:"专业的跨境数字营销服务商，将您的产品和服务推向全球受众，让您享受到一站式跟踪服务。",
    blockList2:[
       {title:'专业团队',text:'十年海外推广经验，专业优化师团队',img:require('../static/images/google-block2-1.png')},
       {title:'精准定位',text:'一站式服务，高精准定位',img:require('../static/images/google-block2-2.png')},
       {title:'实时跟踪',text:'专业各种产品，广告投放贴心服务',img:require('../static/images/google-block2-3.png')},
       {title:'实时数据监控',text:'为您进行专业的广告投放建议和分析',img:require('../static/images/google-block2-4.png')}, 
    ],
    };
  },
  methods:{
    changeLi(index){
      this.activeIndex = index;
      this.lastImg  = require('../static/images/google-last' + index  + '.png')

    }
  }
};
</script>

<style>
 .container1080{
  width: 1080px;
  margin: auto;
  padding:80px 0 !important;
}
.titleThis{
    color: rgb(2, 27, 78);
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 80px;
}

.blockSmall{
    width: 230px;
    height: 340px;
    border-radius: 4px;
    background: #fff;
    text-align: center;
    margin-right: 30px;
}
.imgSmall{
    width: 85px;
    margin: 40px auto;
    display: block;
}
.titleSmall{
  color: #2b2b2c;
    font-size: 15px;
    margin-bottom: 30px;
}
.textSmall{
  text-align: left;
    font-size: 13px;
    color: #727476;
    margin: 0 10px 30px;
}
.google-blueBlock{
  width: 100%;
  color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 120px 0;
    height: 292px;
    background-image: url('../static/images/google-blue.png')
}
.bluetitle{
  color: #fff;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
}
.bluetext{
     font-size: 14px;
    color: #fff;
    text-align: center;
}
.googleAdvLeft{
  width: 380px;
  text-align: left;
  display: inline-block;
  margin-top: 40px;
  margin-right:30px
}

.googleLi li{
  color:rgb(46 46 46 / 48%);
}
.checked{
  color:#ffd25b !important;
}
.lastImg{
 width: 500px;
    vertical-align: top!important;
}

</style>